import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("货品名称"),
    dataIndex: "material_name",
    key: "material_name",
    ellipsis: true,
  },
  {
    title: T("货品编号"),
    dataIndex: "material_number",
    key: "material_number",
  },
  {
    title: T("批次编号"),
    dataIndex: "number",
    key: "number",
  },
  {
    title: T("库存数量"),
    dataIndex: "remain_quantity",
    key: "remain_quantity",
  },
  {
    title: T("操作"),
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
